<template>
    <!--    컬렉션 리스트 레이아웃<br>-->
    <div class="user_gallery_list_wrap">
        <div class="user_gallery_list_box" v-for="(gallery, index) in galleryData" :key="`gallery${gallery.c_idx}`" @click="movePage(`/gallery/${gallery.c_idx}`)">
            <div class="user_gallery_list_img">
                <img :src="returnImage(gallery)" width="378" height="240" alt="">
            </div>
            <div class="user_gallery_list_title">
                <span>{{ gallery.c_title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";
export default {
    name: "GalleryListCreatorLayout",
    mixins: [imageOption,imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        galleryData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}}
    },
    data() {
        return{
            collectionLists: 3,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        movePage(url) {
            this.$router.push(url);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        returnImage(gallery) {
            if(util.isEmpty(gallery) || util.isEmpty(gallery.Files) || util.isEmpty(gallery.Files.Background)) {
                return "";
            }
            return `${gallery.Files.Background[0].org_url}?${this.setImageOptions(378, 240, this.returnExtension(gallery.Files.Background[0].org_url))}`;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
